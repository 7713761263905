.yellow{
    background-color: #FFE082;
}

.priceChanged {
   background: #ffb74d;
   animation: changeColor 1s ease infinite alternate;
}

@keyframes changeColor {
   0%,
   100% {
      background: #ffb74d;
   }

   100% {
      background: white;
   }
}